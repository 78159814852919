import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <p className="logo-text">Viewer Portal</p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <img width={250} src={"/viewer_portal_logo.png"} />
      </div>
    </div>
  );
}

export default App;
